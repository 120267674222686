import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { get } from "../services/api/api"
import Mission from "../models/mission"
import { parseMissionsListData } from "../services/utils/parseFunctions"
import { UsersContext } from "./users"
import { ChallengeContext } from "./challenge"
import { cacheImages, deepCopy, enumAsArray } from "../services/utils/utils"
import { MissionPeriod } from "../services/config/enum"
import { MainContext } from "./main"
import { oldChallengeContractId } from "../services/config/constants"

interface MissionsContextInterface {
  missionsLoading: boolean
  missionsError: boolean
  missionsHistoryLoading: boolean
  missionsHistoryUpdating: boolean
  missionsHistoryError: boolean
  missions: Mission[]
  setMissions: Dispatch<SetStateAction<Mission[]>>
  missionsHistory: Mission[]
  missionsHistoryNextToken: string | null
  currentSlide: number
  setCurrentSlide: Dispatch<SetStateAction<number>>
  updateAll: () => void
  getMissionsHistory: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  completedMissionsCountLoading: boolean
  completedMissionsCountError: boolean
  completedMissionsCount: number
  getCompletedMissionsCount: (withLoading?: boolean) => Promise<boolean>
}

const MissionsContext = createContext<MissionsContextInterface>({
  missionsLoading: true,
  missionsError: false,
  missionsHistoryLoading: true,
  missionsHistoryUpdating: false,
  missionsHistoryError: false,
  missions: [],
  setMissions: () => {},
  missionsHistory: [],
  missionsHistoryNextToken: null,
  currentSlide: 0,
  setCurrentSlide: () => {},
  updateAll: () => {},
  getMissionsHistory: async () => true,
  selectedTab: 0,
  setSelectedTab: () => {},
  completedMissionsCountLoading: true,
  completedMissionsCountError: false,
  completedMissionsCount: 0,
  getCompletedMissionsCount: async () => true,
})

const MissionsController = ({ children }: { children: ReactNode }) => {
  const {
    getUserInfo,
    setUpdatingMissions,
    currentMission,
    setCurrentMission,
    setViewTutorial,
    isChallengeOver,
    contract,
    isOldChallenge,
  } = useContext(MainContext)
  const { getLeaderboard } = useContext(UsersContext)
  const { getChallenge, getSavings } = useContext(ChallengeContext)

  // loadings
  const [missionsLoading, setMissionsLoading] = useState<boolean>(true)
  const [missionsHistoryLoading, setMissionsHistoryLoading] =
    useState<boolean>(true)
  const [missionsHistoryUpdating, setMissionsHistoryUpdating] =
    useState<boolean>(false)
  const [completedMissionsCountLoading, setCompletedMissionsCountLoading] =
    useState<boolean>(true)

  // errors
  const [missionsError, setMissionsError] = useState<boolean>(false)
  const [missionsHistoryError, setMissionsHistoryError] =
    useState<boolean>(false)
  const [completedMissionsCountError, setCompletedMissionsCountError] =
    useState<boolean>(false)

  // states
  const [missions, setMissions] = useState<Mission[]>([])
  const [missionsHistory, setMissionsHistory] = useState<Mission[]>([])
  const [missionsHistoryNextToken, setMissionsHistoryNextToken] = useState<
    string | null
  >(null)
  const [completedMissionsCount, setCompletedMissionsCount] =
    useState<number>(0)
  const [currentSlide, setCurrentSlide] = useState<number>(0) // missions carousel current slide
  const [selectedTab, setSelectedTab] = useState<number>(0) // missions tabs current tab

  // get missions list
  const getMissionsList = async () => {
    const { data } = await get("/web/mission/list")

    // hide tutorial if there are no missions (tutorial only works with missions)
    if (!data.items.length) {
      setViewTutorial(false)
    }

    return data.items
  }

  // get user missions list
  const getUserMissionsList = async () => {
    const { data } = await get("/web/mission/user/list")

    return data.items
  }

  // get missions
  const getMissions = async (withLoading = true) => {
    if (withLoading) {
      setMissionsLoading(true)
    }
    setMissionsError(false)

    try {
      let result
      try {
        result = await Promise.all([getMissionsList(), getUserMissionsList()])
      } catch {
        try {
          result = await Promise.all([getMissionsList(), getUserMissionsList()])
        } catch {
          result = await Promise.all([getMissionsList(), getUserMissionsList()])
        }
      }

      // parse data
      const unifiedList = parseMissionsListData(result[0], result[1])

      // sort data by MissionPeriod enum (based on the enum order)
      const missionPeriodEnumArray = enumAsArray(MissionPeriod)
      unifiedList.sort(
        (a: Mission, b: Mission) =>
          missionPeriodEnumArray.indexOf(a.period) -
          missionPeriodEnumArray.indexOf(b.period)
      )

      // cache images
      await cacheImages(unifiedList.map((item) => item.image))

      console.log("missions list", unifiedList)
      setMissions(unifiedList)

      // update current mission
      if (
        currentMission &&
        unifiedList.some((item) => item.missionId === currentMission.missionId)
      ) {
        const missionToSet = unifiedList.find(
          (item) => item.missionId === currentMission.missionId
        )
        setCurrentMission(deepCopy(missionToSet))
        localStorage.setItem("currentMission", JSON.stringify(missionToSet))
      }

      setMissionsLoading(false)
      setUpdatingMissions(false)
    } catch (e) {
      console.log("missions list error", e)
      setMissionsError(true)
    }
  }

  // get missions history
  const getMissionsHistory = async (
    withLoading = true,
    withNextToken = true
  ) => {
    if (withLoading) {
      setMissionsHistoryLoading(true)
    }
    setMissionsHistoryUpdating(true)
    setMissionsHistoryError(false)

    try {
      const { data } = await (isOldChallenge
        ? get(
            missionsHistoryNextToken && withNextToken
              ? `/web/mission/user/history?nextToken=${missionsHistoryNextToken}&teamcontract_id=${oldChallengeContractId}`
              : `/web/mission/user/history?teamcontract_id=${oldChallengeContractId}`
          )
        : get(
            missionsHistoryNextToken && withNextToken
              ? `/web/mission/user/history?nextToken=${missionsHistoryNextToken}${
                  isChallengeOver && contract
                    ? "&teamcontract_id=" + contract.id
                    : ""
                }`
              : `/web/mission/user/history${
                  isChallengeOver && contract
                    ? "?teamcontract_id=" + contract.id
                    : ""
                }`
          ))

      // parse data (show satisfied and correct missions only)
      data.items = data.items.filter((item: any) => item.satisfied)

      if (missionsHistoryNextToken && withNextToken) {
        console.log("missions history", [...missionsHistory, ...data.items])
        setMissionsHistory((current) => [...current, ...data.items])
      } else {
        console.log("missions history", data.items)
        setMissionsHistory(data.items)
      }
      setMissionsHistoryNextToken(data.nextToken)

      setMissionsHistoryLoading(false)
      setMissionsHistoryUpdating(false)

      return true
    } catch (e) {
      console.log("missions history error", e)
      setMissionsHistoryError(true)
      setMissionsHistoryUpdating(false)

      return false
    }
  }

  // get completed missions count
  const getCompletedMissionsCount = async (withLoading = true) => {
    if (withLoading) {
      setCompletedMissionsCountLoading(true)
    }

    try {
      const { data } = await (isOldChallenge
        ? get(
            `/web/mission/mission/user?teamcontract_id=${oldChallengeContractId}`
          )
        : get(
            `/web/mission/mission/user${
              isChallengeOver && contract
                ? "?teamcontract_id=" + contract.id
                : ""
            }`
          ))
      console.log("completed missions count", data.missions ?? 0)

      setCompletedMissionsCount(data.missions ?? 0)

      setCompletedMissionsCountLoading(false)

      return true
    } catch (e) {
      console.log("completed missions count error", e)
      setCompletedMissionsCountLoading(false)
      setCompletedMissionsCountError(true)

      return false
    }
  }

  // update missions list and user points
  const updateAll = async () => {
    getMissions(false)
    getMissionsHistory(false, false)
    setTimeout(() => {
      getCompletedMissionsCount(false)
      getUserInfo()
      getLeaderboard(false, false)
      getChallenge(false)
      getSavings(false)
    }, 3000)
    setTimeout(() => {
      getUserInfo()
    }, 5500)
  }

  // initial fetch
  useEffect(() => {
    getMissions()
    getMissionsHistory()
    getCompletedMissionsCount()
  }, [])

  return (
    <MissionsContext.Provider
      value={{
        missionsLoading,
        missionsError,
        missionsHistoryLoading,
        missionsHistoryUpdating,
        missionsHistoryError,
        missions,
        setMissions,
        missionsHistory,
        missionsHistoryNextToken,
        currentSlide,
        setCurrentSlide,
        updateAll,
        getMissionsHistory,
        selectedTab,
        setSelectedTab,
        completedMissionsCountLoading,
        completedMissionsCountError,
        completedMissionsCount,
        getCompletedMissionsCount,
      }}
    >
      {children}
    </MissionsContext.Provider>
  )
}
export { MissionsController, MissionsContext }
