import { Grow, Modal, Stack } from "@mui/material"
import { t } from "i18next"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import {
  challengeRules,
  defaultBoxShadowDarker,
} from "../../../services/config/constants"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import Text from "../../global/common/Text"
import prize1Image from "../../../assets/images/prizes/prize-1.jpg"

const DrawPrizesFeedback = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { isMobile, user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={t("mission_completed")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Stack
          alignItems="center"
          style={{
            backgroundColor: colors.backgroundWhite,
            width: isMobile ? "calc(100% - 44px)" : 560,
            maxWidth: isMobile ? 375 : 560,
            height: "auto",
            borderRadius: 16,
            paddingTop: 70,
            paddingBottom: 24,
            paddingInline: 30,
            position: "relative",
          }}
        >
          <img
            src={user?.profileImage}
            style={{
              width: 80,
              height: 80,
              borderRadius: "100%",
              boxShadow: defaultBoxShadowDarker,
              position: "absolute",
              top: -40,
            }}
            alt=""
          />
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
          >
            Congratulazioni!
            <br />
            Hai raggiunto i 400 pt
          </Title>
          <Stack
            direction="row"
            justifyContent="flex-start"
            style={{
              width: "100%",
              gap: 30,
              marginTop: 28,
            }}
          >
            {/* prize card */}
            {!isMobile && (
              <div style={{ width: 156, minWidth: 156 }}>
                <Stack
                  style={{
                    width: "100%",
                    height: 228,
                    backgroundColor: "#D02963",
                    borderRadius: 10,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={prize1Image}
                    style={{ objectFit: "cover", width: "100%", height: 117 }}
                  />
                  <div
                    className="center"
                    style={{
                      width: "fit-content",
                      height: 20,
                      borderRadius: 18,
                      backgroundColor: "#D02963",
                      position: "absolute",
                      top: 87,
                      left: 12,
                      paddingInline: 8,
                    }}
                  >
                    <Text
                      fontSize={11}
                      fontWeight={500}
                      color={colors.textWhite}
                      style={{ marginTop: 2 }}
                    >
                      Ad estrazione
                    </Text>
                  </div>
                  <Title
                    fontSize={14}
                    fontWeight={600}
                    lineHeight="20px"
                    paddingLeft={12}
                    paddingRight={12}
                    color={colors.textWhite}
                    style={{ marginTop: 8, height: 60 }}
                  >
                    Un weekend per 2 persone allo Chalet Levissima 3000
                  </Title>
                  <Text
                    fontSize={11}
                    fontWeight={400}
                    paddingLeft={12}
                    paddingRight={12}
                    color={colors.textWhite}
                    style={{ marginTop: 8 }}
                  >
                    Disponibilità: 3
                  </Text>
                </Stack>
              </div>
            )}
            <Stack style={{ width: "100%" }}>
              <Text fontSize={16} lineHeight="22px" fontWeight={400}>
                Da questo momento anche tu potrai tentare di vincere uno dei 3
                premi extra messi a disposizione da Levissima.
                <br />
                <br />I premi verranno estratti al termine della challenge.
                <br />
                <br />
                <a
                  style={{
                    color: colors.primary,
                  }}
                  href={challengeRules}
                  target="__blank"
                >
                  Leggi qui il regolamento completo.
                </a>
              </Text>
            </Stack>
          </Stack>
          <Button
            width={isMobile ? "100%" : 280}
            style={{ marginTop: 28 }}
            onClick={() => {
              setOpen(false)
            }}
            title={t("i_understand")}
          >
            {t("i_understand")}
          </Button>
        </Stack>
      </Grow>
    </Modal>
  )
}

export default DrawPrizesFeedback
