import { ButtonBase, ClickAwayListener, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../common/Title"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import Text from "../common/Text"
import { useNavigate } from "react-router-dom"
import {
  defaultBoxShadowDarker,
  desktopColumnsGap,
  desktopMaxWidth,
  desktopPadding,
  navBarHeight,
} from "../../../services/config/constants"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../../controllers/main"
import menuWhiteIcon from "../../../assets/icons/menu-white.svg"
import closeWhiteIcon from "../../../assets/icons/close-white.svg"
import FocusTrap from "@mui/material/Unstable_TrapFocus"
import { ChallengeContext } from "../../../controllers/challenge"
import { scrollWindowToTop } from "../../../services/utils/utils"
import partnerPoweredByAWorldLogo from "../../../assets/images/partner-powered-by-aworld.png"

const NavBarDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)

  // menu popover open
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  // change item
  const changeItem = () => {
    const pathname = window.location.pathname

    if (challenge) {
      return pathname.includes("/challenge")
        ? { index: 1, label: t("challenge") }
        : pathname.includes("/performance")
        ? { index: 2, label: t("performance") }
        : { index: 0, label: t("home") }
    }

    return pathname.includes("/performance")
      ? { index: 1, label: t("performance") }
      : { index: 0, label: t("home") }
  }

  // current menu item
  const [selectedItem, setSelectedItem] = useState<{
    index: number
    label: string
  }>(changeItem())

  // change item when location is changed in other components
  useEffect(() => {
    setSelectedItem(changeItem())
  }, [window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  // focus main content (accessibility)
  const focusMainContent = () => {
    const main = document.getElementById("main-content")!
    main.tabIndex = 0
    main.focus()
    setTimeout(() => main.removeAttribute("tabindex"), 500)
  }

  // reduce navbar height if path === / and page is scrolled
  const [compressed, setCompressed] = useState<boolean>(false)

  useEffect(() => {
    const mainContainer = document.getElementById("main-container")!
    mainContainer.addEventListener("scroll", () => {
      if (mainContainer.scrollTop > 10) {
        setCompressed(true)
      } else {
        setCompressed(false)
      }
    })
  }, [])

  return (
    <nav
      role="navigation"
      style={{
        width: "100%",
        height:
          window.location.pathname === "/" && !compressed ? 177 : navBarHeight,
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 99,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          window.location.pathname === "/" && !compressed
            ? "rgba(0, 0, 0, 0)"
            : colors.background,
        transition: "200ms",
      }}
    >
      <ButtonBase
        aria-label="Rigeneriamo insieme"
        style={{
          position: "absolute",
          opacity: window.location.pathname === "/" ? 1 : 0,
          transition: "100ms",
          zIndex: 1,
        }}
        onClick={() => {
          window.open("https://rigeneriamoinsieme.it")
        }}
      >
        <img
          src={partnerPoweredByAWorldLogo}
          style={{
            height: 49,
          }}
          alt={t("partner_logo")}
          aria-hidden={window.location.pathname === "/" ? "false" : "true"}
        />
      </ButtonBase>
      <ButtonBase
        id="skip-link"
        style={{
          position: "absolute",
          top: 16,
          color: colors.primary,
          cursor: "default",
          opacity: 0,
          left: "-200vw",
        }}
        onFocus={() => {
          const link = document.getElementById("skip-link")!
          link.style.opacity = "1"
          link.style.left = ""
        }}
        onBlur={() => {
          const link = document.getElementById("skip-link")!
          link.style.opacity = "0"
          link.style.left = "-200vw"
        }}
        onClick={() => {
          const link = document.getElementById("skip-link")!
          link.style.opacity = "0"
          link.style.left = "-200vw"

          focusMainContent()
        }}
        aria-label={t("skip_to_main_content")}
      >
        <Text fontSize={18} fontWeight={700} color={colors.primary}>
          {t("skip_to_main_content")}
        </Text>
      </ButtonBase>
      <ClickAwayListener
        onClickAway={() => {
          setMenuOpen(false)
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: `calc(100% - (${desktopPadding} * 2))`,
            maxWidth: desktopMaxWidth,
            height: 60,
            gap: desktopColumnsGap,
            position: "relative",
          }}
          role="presentation"
        >
          {/* menu */}
          <FocusTrap open={menuOpen}>
            <div
              style={{
                width: menuOpen ? 238 : 185,
                height: menuOpen ? (challenge ? 184 : 122) : 60,
                borderRadius: 30,
                backgroundColor: colors.backgroundWhite,
                position: "absolute",
                top: 0,
                transition: "250ms",
                transitionTimingFunction: "cubic-bezier(.17, .8, 0.2, 1.15)",
                alignItems: "flex-start",
                boxShadow: defaultBoxShadowDarker,
              }}
              role="presentation"
            >
              {/* open and close button */}
              <ButtonBase
                disableRipple
                style={{
                  width: menuOpen ? 64 : "100%",
                  height: 60,
                  position: "absolute",
                  justifyContent: "flex-start",
                  paddingLeft: 9,
                  borderRadius: 30,
                  zIndex: 1,
                }}
                onClick={(e) => {
                  setMenuOpen((current) => !current)
                }}
                aria-label={menuOpen ? t("close_menu") : t("open_menu")}
              >
                <div
                  className="center"
                  style={{
                    width: 46,
                    height: 46,
                    borderRadius: "100%",
                    backgroundColor: colors.primary,
                    transform: menuOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "250ms",
                  }}
                >
                  <img
                    src={menuWhiteIcon}
                    style={{
                      width: 26,
                      position: "absolute",
                      opacity: menuOpen ? 0 : 1,
                      transition: "250ms",
                    }}
                    alt=""
                  />
                  <img
                    src={closeWhiteIcon}
                    style={{
                      width: 26,
                      position: "absolute",
                      opacity: menuOpen ? 1 : 0,
                      transition: "250ms",
                    }}
                    alt=""
                  />
                </div>
              </ButtonBase>
              {/* current page */}
              <Title
                fontSize={18}
                lineHeight="46px"
                color={colors.primary}
                style={{
                  opacity: menuOpen ? 0 : 1,
                  transition: "200ms",
                  position: "absolute",
                  top: 9,
                  left: 67,
                }}
                ariaHidden={menuOpen}
              >
                {selectedItem.label}
              </Title>
              {/* menu items */}
              <Stack
                gap={2}
                style={{
                  width: menuOpen ? 171 : 0,
                  height: menuOpen ? (challenge ? 170 : 108) : 0,
                  marginTop: 7,
                  marginLeft: 67,
                  opacity: menuOpen ? 1 : 0,
                  transition: "250ms",
                  transitionTimingFunction: "cubic-bezier(.17, .8, 0.2, 1.15)",
                  overflowY: "hidden",
                  overflowX: "visible",
                }}
              >
                <MenuItem
                  menuOpen={menuOpen}
                  setMenuOpen={setMenuOpen}
                  selected={selectedItem.index === 0}
                  path="home"
                  focusMainContent={focusMainContent}
                />
                {challenge ? (
                  <MenuItem
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    selected={selectedItem.index === 1}
                    path="challenge"
                    focusMainContent={focusMainContent}
                  />
                ) : null}
                <MenuItem
                  menuOpen={menuOpen}
                  setMenuOpen={setMenuOpen}
                  selected={
                    challenge
                      ? selectedItem.index === 2
                      : selectedItem.index === 1
                  }
                  path="performance"
                  focusMainContent={focusMainContent}
                />
              </Stack>
            </div>
          </FocusTrap>
          {/* performance button */}
          <ButtonBase
            disableRipple
            style={{
              width: 185,
              height: "100%",
              borderRadius: 30,
              backgroundColor: colors.backgroundWhite,
              position: "absolute",
              right: 0,
              boxShadow: defaultBoxShadowDarker,
            }}
            onClick={() => {
              setMenuOpen(false)
              setSelectedItem({ label: t("performance"), index: 2 })
              scrollWindowToTop()
              navigate("/performance")

              focusMainContent()
            }}
            aria-label={t("performance")}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: "100%",
                paddingLeft: 9,
              }}
            >
              <div
                style={{
                  width: 46,
                  height: 46,
                  borderRadius: "100%",
                  position: "relative",
                  zIndex: 1,
                  backgroundImage: `url(${
                    user!.profileImage
                  }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                  backgroundSize: "105%, 100%",
                  backgroundPosition: "center",
                }}
              />
              <Title
                fontSize={18}
                color={colors.primary}
                style={{
                  transition: "400ms",
                  position: "absolute",
                  zIndex: 1,
                  right: 18,
                }}
              >
                {user?.points} {t("points")}
              </Title>
            </Stack>
          </ButtonBase>
        </Stack>
      </ClickAwayListener>
    </nav>
  )
}

const MenuItem = ({
  menuOpen,
  setMenuOpen,
  selected,
  path,
  focusMainContent,
}: {
  menuOpen: boolean
  setMenuOpen: Dispatch<SetStateAction<boolean>>
  selected: boolean
  path: string
  focusMainContent: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ButtonBase
      disabled={!menuOpen}
      style={{
        width: 160,
        height: 46,
        backgroundColor: selected ? colors.primary : "rgba(0, 0, 0, 0)",
        borderRadius: 32,
        justifyContent: "flex-start",
        paddingLeft: 12,
      }}
      aria-hidden={menuOpen ? "false" : "true"}
      tabIndex={menuOpen ? 0 : -1}
      onClick={() => {
        scrollWindowToTop()
        if (path === "home") {
          navigate("/")
        } else {
          navigate(`/${path}`)
        }

        focusMainContent()
        setMenuOpen(false)
      }}
    >
      <Title
        fontSize={18}
        lineHeight="46px"
        color={selected ? colors.textWhite : colors.primary}
        style={{ marginTop: 2 }}
      >
        {t(path)}
      </Title>
    </ButtonBase>
  )
}

export default NavBarDesktop
