import { ButtonBase, Skeleton, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightPrimaryIcon from "../../assets/icons/chevron-right-primary.svg"
import pointsStarIcon from "../../assets/icons/points-star.svg"
import rocketIcon from "../../assets/icons/rocket.svg"
import FadeFromTop from "../animations/FadeFromTop"
import {
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  SetStateAction,
  useContext,
  useMemo,
} from "react"
import LeaderboardTop3 from "./common/LeaderboardTop3"
import {
  challengeRules,
  defaultBoxShadowDarker,
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  italianFootprintAverage,
  navBarHeight,
} from "../../services/config/constants"
import AppearFromSide from "../animations/AppearFromSide"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { UsersContext } from "../../controllers/users"
import { useTranslation } from "react-i18next"
import LeaderboardListItem from "./common/LeaderboardListItem"
import PointsHistoryCardDesktop from "./desktop/PointsHistoryCardDesktop"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../services/utils/utils"
import { MissionsContext } from "../../controllers/missions"
import { FootprintContext } from "../../controllers/footprint"
import settingsIcon from "../../assets/icons/settings.svg"
import prizesBannerBackground from "../../assets/images/prizes-banner-background.png"
import { MainContext } from "../../controllers/main"
import { ChallengeContext } from "../../controllers/challenge"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import { MissionSubType } from "../../services/config/enum"
import closeIcon from "../../assets/icons/close.png"
import Button from "../global/common/Button"

const PerformanceDesktop = ({
  tabs,
  selectedTab,
  setSelectedTab,
  setSelectedIndex,
  tabRefs,
  focusTabContent,
  onKeyDown,
  screenReaderEnabled,
  displayedLeaderboardLoading,
  showDrawPrizesBanner,
  setShowDrawPrizesBanner,
  userPosition,
}: {
  tabs: string[]
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  setSelectedIndex: Dispatch<SetStateAction<number>>
  tabRefs: MutableRefObject<HTMLButtonElement[]>
  focusTabContent: () => void
  onKeyDown: (event: KeyboardEvent<HTMLButtonElement>) => void
  screenReaderEnabled: MutableRefObject<boolean>
  displayedLeaderboardLoading: boolean
  showDrawPrizesBanner: boolean
  setShowDrawPrizesBanner: Dispatch<SetStateAction<boolean>>
  userPosition: number
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    user,
    setViewTutorial,
    setCurrentMission,
    isChallengeOver,
    setIsOldChallenge,
  } = useContext(MainContext)
  const { leaderboard, leaderboardError, getLeaderboard } =
    useContext(UsersContext)
  const { challenge } = useContext(ChallengeContext)
  const { missions, completedMissionsCount } = useContext(MissionsContext)
  const { userFootprint, formsList } = useContext(FootprintContext)

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[{ label: t("performance") }]}
        selectedItem={0}
        style={{ marginTop: 22 }}
      />
      {/* heading for screen readers only */}
      {/* recap card */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title
                fontSize={36}
                lineHeight="46px"
                component="h1"
                color={colors.primary}
                style={{ fontStyle: "italic" }}
              >
                {t("performance").toUpperCase()}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("monitor_your_progress")}
              </Text>
            </AppearFromSide>
            {challenge ? (
              <Appear style={{ position: "absolute", right: 0 }} fade>
                <AvatarInfoButton
                  title={t("view_tutorial")}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/")
                    setViewTutorial(true)
                  }}
                />
              </Appear>
            ) : null}
          </Stack>
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: colors.background,
              marginTop: 40,
              borderRadius: 16,
              padding: 60,
              gap: 13,
            }}
          >
            {/* first column */}
            <Stack
              style={{
                width: "30%",
                minWidth: "30%",
                maxWidth: "30%",
                height: "auto",
                position: "relative",
              }}
            >
              <FadeFromTop>
                <div
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: "100%",
                    backgroundImage: `url(${
                      user!.profileImage
                    }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                    backgroundSize: "105%, 100%",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                />
              </FadeFromTop>
              <FadeFromTop delay={0.025} style={{ marginTop: 14 }}>
                <Title
                  fontSize={30}
                  lineHeight="38px"
                  style={{
                    width: "90%",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    wordWrap: "break-word",
                  }}
                >
                  {user?.first_name} {user?.last_name}
                </Title>
              </FadeFromTop>
              <FadeFromTop delay={0.05} style={{ marginTop: 10 }}>
                <Text fontSize={18} lineHeight="25px" fontWeight={400}>
                  {t("user_active_since")} {user?.active_since}
                </Text>
              </FadeFromTop>
              <FadeFromTop
                delay={0.05}
                style={{ position: "absolute", bottom: 0 }}
              >
                <ButtonBase
                  disableRipple
                  aria-label={t("settings")}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/settings")
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ height: 28, gap: 11 }}
                  >
                    <img
                      src={settingsIcon}
                      style={{ width: 24, height: 24 }}
                      alt=""
                    />
                    <Title
                      fontSize={20}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ textDecoration: "underline" }}
                    >
                      {t("settings")}
                    </Title>
                  </Stack>
                </ButtonBase>
              </FadeFromTop>
            </Stack>
            {/* second column */}
            <Stack
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                gap: 30,
              }}
            >
              <FadeFromTop style={{ width: "100%" }}>
                <ButtonBase
                  disabled={
                    !userFootprint &&
                    !missions.some(
                      (mission) =>
                        mission.missionSubType === MissionSubType.TYPEFORM_BASE
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: 16,
                    border: "1px solid " + colors.primary,
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    if (
                      formsList.length &&
                      formsList.some((item) => !item.isRefine)
                    ) {
                      const missionToSet = missions.find(
                        (mission) =>
                          mission.missionSubType ===
                          MissionSubType.TYPEFORM_BASE
                      )!
                      setCurrentMission(missionToSet)
                      localStorage.setItem(
                        "currentMission",
                        JSON.stringify(missionToSet)
                      )
                      navigate("/performance/footprint/form")
                    } else {
                      navigate("/performance/footprint")
                    }
                  }}
                  aria-label={t("your_carbon_footprint")}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: 92,
                      borderRadius: 16,
                      backgroundColor: colors.backgroundWhite,
                      paddingLeft: 22,
                      gap: 22,
                      position: "relative",
                    }}
                  >
                    <img
                      src={footprintDoughnutImage}
                      style={{ height: 58 }}
                      alt=""
                    />
                    <Stack style={{ gap: 10 }}>
                      <Title fontSize={20} lineHeight="24px">
                        {t("your_carbon_footprint")}
                      </Title>
                      {!userFootprint &&
                      !missions.some(
                        (mission) =>
                          mission.missionSubType ===
                          MissionSubType.TYPEFORM_BASE
                      ) ? (
                        <div style={{ height: 19 }} />
                      ) : (
                        <Text fontSize={16} fontWeight={400}>
                          {userFootprint
                            ? `${Math.abs(
                                Math.round(
                                  ((userFootprint.footprint -
                                    italianFootprintAverage) /
                                    ((userFootprint.footprint +
                                      italianFootprintAverage) /
                                      2)) *
                                    100
                                )
                              )}% ${
                                userFootprint.footprint >
                                italianFootprintAverage
                                  ? t("above_average")
                                  : t("below_average")
                              }`
                            : t("answer_the_survey")}
                        </Text>
                      )}
                    </Stack>
                    <Stack
                      justifyContent="flex-end"
                      style={{
                        position: "absolute",
                        right:
                          !userFootprint &&
                          !missions.some(
                            (mission) =>
                              mission.missionSubType ===
                              MissionSubType.TYPEFORM_BASE
                          )
                            ? 28
                            : 85,
                      }}
                    >
                      <Title
                        fontSize={26}
                        lineHeight="30px"
                        color={colors.primary}
                        textAlign="right"
                      >
                        {userFootprint
                          ? userFootprint.footprint.toString().replace(".", ",")
                          : "--"}
                      </Title>
                      <Text fontSize={16} lineHeight="30px" fontWeight={400}>
                        {t("tons")} CO2e
                      </Text>
                    </Stack>
                    {!userFootprint &&
                    !missions.some(
                      (mission) =>
                        mission.missionSubType === MissionSubType.TYPEFORM_BASE
                    ) ? null : (
                      <img
                        src={chevronRightPrimaryIcon}
                        style={{ height: 20, position: "absolute", right: 28 }}
                        alt=""
                      />
                    )}
                  </Stack>
                </ButtonBase>
              </FadeFromTop>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: 160,
                  gap: desktopColumnsGap,
                }}
              >
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <img
                      src={pointsStarIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {user?.points}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      style={{ marginTop: 5 }}
                    >
                      {t("points_extended")}
                    </Text>
                  </Stack>
                </FadeFromTop>
                <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <img
                      src={rocketIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {completedMissionsCount}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      textAlign="center"
                      style={{ marginTop: 5, width: 100 }}
                    >
                      {completedMissionsCount === 1
                        ? t("completed_mission")
                        : t("completed_missions")}
                    </Text>
                  </Stack>
                </FadeFromTop>
                {/* <FadeFromTop
                  style={{ width: "100%", height: "100%" }}
                  delay={0.05}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                      border: "1px solid " + colors.primary,
                      backgroundColor: colors.backgroundWhite,
                    }}
                  >
                    <img
                      src={trophyIcon}
                      style={{ height: 30, marginTop: 21 }}
                      alt=""
                    />
                    <Title
                      fontSize={26}
                      lineHeight="28px"
                      color={colors.primary}
                      style={{ marginTop: 16 }}
                    >
                      {user?.position
                        ? user.position + "°"
                        : challenge
                        ? (challenge!.currentAmount
                            ? Math.round(
                                ((completedMissionsCount /
                                  challenge!.currentAmount) *
                                  100 +
                                  Number.EPSILON) *
                                  100
                              ) / 100
                            : 0
                          )
                            .toString()
                            .replace(".", ",") + "%"
                        : 0 + "%"}
                    </Title>
                    <Text
                      fontSize={16}
                      lineHeight="18px"
                      fontWeight={400}
                      textAlign="center"
                      style={{ marginTop: 5, width: 100 }}
                    >
                      {user?.position
                        ? t("in_the_rankings")
                        : t("challenge_contribution")}
                    </Text>
                  </Stack>
                </FadeFromTop> */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* prizes banner */}
      {isChallengeOver && userPosition <= 5 ? (
        <PaddingContainerDesktop>
          <Stack
            justifyContent="center"
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
              height: 300,
              backgroundImage: `url(${prizesBannerBackground})`,
              backgroundSize: "cover",
              borderRadius: 16,
              marginTop: 46,
            }}
          >
            {user &&
            leaderboard.some(
              (item) => item.position === userPosition && item.sub !== user.sub
            ) ? (
              <Stack gap={1} style={{ paddingLeft: "50%", paddingRight: 80 }}>
                <Title fontSize={26} color={colors.primary}>
                  COMPLIMENTI!
                  <br />
                  SEI AL {userPosition}° POSTO A PARI MERITO
                </Title>
                <Text fontSize={18} lineHeight="24px" fontWeight={400}>
                  <strong>Grazie per il tuo impegno.</strong>
                  <br />
                  <strong>Siamo felici di vedere questa partecipazione.</strong>
                  <br />
                  Nel caso di partecipanti a pari merito, procederemo ad
                  un'estrazione per decretare il vincitore, come previsto dal{" "}
                  <span
                    role="button"
                    style={{
                      color: colors.primary,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(challengeRules)
                    }}
                  >
                    regolamento
                  </span>
                  . Riceverei tutte le informazioni e tempistiche via email.{" "}
                  <strong>Buona fortuna!</strong>
                </Text>
              </Stack>
            ) : (
              <Stack gap={1} style={{ paddingLeft: "50%", paddingRight: 80 }}>
                <Title fontSize={26} color={colors.primary}>
                  COMPLIMENTI!
                  <br />
                  SEI AL {userPosition}° POSTO IN CLASSIFICA
                </Title>
                <Text fontSize={18} lineHeight="24px" fontWeight={400}>
                  Hai vinto uno tra i premi messi in palio.
                  <br />
                  <strong>
                    Guarda la tua email per ricevere tutte le informazioni su
                    come ritirarlo.
                  </strong>
                </Text>
              </Stack>
            )}
          </Stack>
        </PaddingContainerDesktop>
      ) : null}
      {/* challenge draw prizes banner */}
      {showDrawPrizesBanner ? (
        <PaddingContainerDesktop style={{ marginTop: 36 }}>
          <AppearFromSide
            delay={0.1}
            fade
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              style={{
                padding: 16,
                borderRadius: 24,
                border: `3px solid ${colors.primary}`,
              }}
            >
              <img
                src={user?.profileImage}
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
                alt=""
              />
              <Stack gap={1} style={{ flex: 1 }}>
                <Title
                  fontSize={16}
                  color={colors.primary}
                  style={{ textTransform: "uppercase" }}
                >
                  Complimenti! Hai raggiunto i 400 pt CON LE TUE MISSIONI
                </Title>
                <Text fontSize={16} fontWeight={400}>
                  Da questo momento anche tu potrai tentare di vincere uno dei 3
                  premi extra che verranno estratti al termine della challenge.
                </Text>
              </Stack>
              <ButtonBase
                aria-label={t("close")}
                style={{
                  width: 36,
                  height: 36,
                  boxShadow: defaultBoxShadowDarker,
                  borderRadius: "100%",
                }}
                onClick={() => {
                  setShowDrawPrizesBanner(false)
                  localStorage.setItem("drawPrizesBannerClosed", "true")
                }}
              >
                <img src={closeIcon} style={{ width: 10, height: 10 }} alt="" />
              </ButtonBase>
            </Stack>
          </AppearFromSide>
        </PaddingContainerDesktop>
      ) : null}
      {/* performance and leaderboard section */}
      <PaddingContainerDesktop style={{ marginTop: 36 }}>
        {!isChallengeOver && (
          <AppearFromSide
            delay={0.1}
            fade
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
            }}
          >
            <Stack
              direction="row"
              style={{
                width: "100%",
                height: 40,
                gap: 10,
              }}
            >
              {tabs.map((tab, index) => (
                <ButtonBase
                  key={index}
                  disabled={displayedLeaderboardLoading}
                  disableRipple
                  style={{
                    width: "auto",
                    height: "100%",
                    borderRadius: 5,
                    paddingInline: 12,
                    backgroundColor:
                      selectedTab === index
                        ? colors.primary
                        : colors.backgroundGray,
                    transition: "200ms",
                  }}
                  onClick={() => {
                    setSelectedTab(index)
                    setIsOldChallenge(index === 1)

                    if (screenReaderEnabled.current) {
                      focusTabContent()
                    }
                  }}
                  aria-label={tab}
                  role="tab"
                  aria-selected={selectedTab === index ? "true" : "false"}
                  ref={(element) => (tabRefs.current[index] = element!)}
                  onKeyDown={onKeyDown}
                  onFocus={() => setSelectedIndex(index)}
                  onFocusVisible={() => {
                    screenReaderEnabled.current = true
                  }}
                  onBlur={() => {
                    screenReaderEnabled.current = false
                  }}
                >
                  <Text
                    fontSize={16}
                    fontWeight={600}
                    color={
                      selectedTab === index ? colors.textWhite : colors.text
                    }
                    style={{ transition: "200ms" }}
                  >
                    {tab}
                  </Text>
                </ButtonBase>
              ))}
            </Stack>
          </AppearFromSide>
        )}
        <Stack
          id="points-and-leaderboard-container"
          direction="row"
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            height: "auto",
            marginTop: 16,
            marginBottom: 62,
          }}
          role="tabpanel"
        >
          {/* performance */}
          <AppearFromSide
            delay={0.1}
            fade
            style={{
              width: "48%",
              minWidth: "48%",
            }}
          >
            <Stack
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", position: "relative" }}
              >
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("missions")}
                </Title>
              </Stack>
              <PointsHistoryCardDesktop
                skeleton={displayedLeaderboardLoading}
              />
              {completedMissionsCount > 5 ? (
                <ButtonBase
                  style={{
                    width: 84,
                    height: 24.5,
                    position: "relative",
                    marginTop: 25,
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/missions")
                  }}
                  aria-label={t("see_all")}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={colors.primary}
                    style={{
                      textDecorationLine: "underline",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("see_all")}
                  </Text>
                </ButtonBase>
              ) : null}
            </Stack>
          </AppearFromSide>
          {/* divider */}
          <div style={{ width: "100%" }} />
          {/* leaderboard */}
          <AppearFromSide
            x={100}
            delay={0.1}
            fade
            style={{
              width: "40%",
              minWidth: "40%",
            }}
          >
            <Stack
              style={{
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", position: "relative" }}
              >
                <Title fontSize={26} lineHeight="46px" component="h2">
                  {t("leaderboard")}
                </Title>
              </Stack>
              {!leaderboardError && (
                <LeaderboardTop3
                  skeleton={displayedLeaderboardLoading}
                  style={{ marginTop: 20 }}
                />
              )}
              {displayedLeaderboardLoading ? (
                <Skeleton
                  variant="rectangular"
                  style={{
                    width: "100%",
                    height: 383,
                    borderRadius: 16,
                    marginTop: 26,
                  }}
                />
              ) : leaderboardError ? (
                <Stack gap={3} style={{ marginTop: 30 }}>
                  <Text fontSize={18} fontWeight={400}>
                    {t("leaderboard_error")}.
                  </Text>
                  <Button
                    title={t("retry")}
                    width={170}
                    style={{ height: 36, alignSelf: "center" }}
                    onClick={() => {
                      getLeaderboard(false, false)
                    }}
                  >
                    {t("retry")}
                  </Button>
                </Stack>
              ) : leaderboard.length > 3 ? (
                <Stack
                  style={{
                    width: "100%",
                    height: 383,
                    borderRadius: 16,
                    backgroundColor: colors.backgroundWhite,
                    boxShadow: "0px 0px 35.68513px 0px rgba(17, 67, 97, 0.08)",
                    marginTop: 26,
                    overflow: "hidden",
                  }}
                >
                  {leaderboard.slice(3, 8).map((item, index) => (
                    <Stack key={index} style={{ height: "20%" }}>
                      {index > 0 &&
                      item.sub !== user!.sub &&
                      leaderboard[index + 2].sub !== user!.sub ? (
                        <div
                          style={{
                            width: "100%",
                            height: 2,
                            backgroundColor: colors.backgroundLight,
                          }}
                        />
                      ) : null}
                      <LeaderboardListItem
                        place={item.position}
                        name={`${item.first_name} ${item.last_name}`}
                        points={item.points}
                        isUser={item.sub === user!.sub}
                        profileImage={item.profileImage}
                      />
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <Text fontSize={18} fontWeight={400} style={{ marginTop: 30 }}>
                  {t("leaderboard_empty_state")}.
                </Text>
              )}
              {leaderboard.length > 8 && !displayedLeaderboardLoading ? (
                <ButtonBase
                  style={{
                    width: 84,
                    height: 24.5,
                    position: "relative",
                    marginTop: 25,
                    alignSelf: "flex-end",
                  }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/performance/leaderboard")
                  }}
                  aria-label={t("see_all")}
                >
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    color={colors.primary}
                    style={{
                      textDecorationLine: "underline",
                      position: "absolute",
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {t("see_all")}
                  </Text>
                </ButtonBase>
              ) : null}
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default PerformanceDesktop
