import { Stack } from "@mui/material"
import Text from "./Text"
import { colors } from "../../../services/config/colors"
import { CSSProperties, useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"

const PointsBadge = ({
  points,
  pointPerActivity,
  style,
}: {
  points: number
  pointPerActivity?: number
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      justifyContent="center"
      style={{
        width: "auto",
        height: isMobile ? 32 : 40,
        paddingInline: isMobile ? 8 : 10,
        borderRadius: 4,
        backgroundColor: colors.backgroundWhite,
        border: isMobile
          ? "1px solid " + colors.primary
          : "1.5px solid " + colors.primary,
        ...style,
      }}
    >
      <Text
        fontSize={isMobile ? 14 : 18}
        fontWeight={700}
        color={colors.primary}
      >
        {pointPerActivity
          ? `${pointPerActivity}+ ${t("points")}`
          : `${points} ${t("points")}`}
      </Text>
    </Stack>
  )
}

export default PointsBadge
