import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerPoweredByAWorldLogo from "../../assets/images/partner-powered-by-aworld.png"
import { useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import introImage from "../../assets/images/onboarding/intro.png"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import checkWhiteIcon from "../../assets/icons/check-white.svg"
import Alert from "../global/common/Alert"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { focusElement } from "../../services/utils/utils"
import {
  challengeRules,
  onboardingElementsMaxWidth,
} from "../../services/config/constants"
import onboardingBackgroundDesktopImage from "../../assets/images/onboarding-background-desktop.jpg"
import AlertFullScreenPDF from "../global/common/AlertFullScreenPDF"

const OnboardingMobile = ({
  height,
  continueButtonWidth,
}: {
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const { windowHeight, signUp, isMobile } = useContext(MainContext)

  // current page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // terms and privacy accepted
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false)
  const [contestAccepted, setContestAccepted] = useState<boolean>(false)

  // loading for signup
  const [loading, setLoading] = useState<boolean>(false)

  // something is missing alert
  const [missingAlertOpen, setMissingAlertOpen] = useState<boolean>(false)

  // terms and privacy alerts
  const [termsAlertOpen, setTermsAlertOpen] = useState<boolean>(false)
  const [privacyAlertOpen, setPrivacyAlertOpen] = useState<boolean>(false)
  const [contestAlertOpen, setContestAlertOpen] = useState<boolean>(false)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: currentPage === 0 ? 760 : 970,
          minHeight: currentPage === 0 ? 760 : 970,
          overflow: "hidden",
          paddingTop: 26,
          zIndex: 1,
        }}
        tabIndex={0}
      >
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
          }}
          alignItems="center"
          aria-hidden={currentPage === 0 ? "false" : "true"}
        >
          {/* header */}
          <FadeFromTop>
            <img
              src={partnerPoweredByAWorldLogo}
              style={{ height: 26 }}
              alt={t("partner_logo_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              <Trans i18nKey="onboarding_welcome" />
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              <Trans i18nKey="onboarding_subtitle" />
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.15} style={{ marginTop: 42 }}>
            <img
              src={introImage}
              style={{ width: 203, height: 214 }}
              alt={t("onboarding_image_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.2} style={{ marginTop: 42 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              fontWeight={400}
              textAlign="center"
              style={{ maxWidth: onboardingElementsMaxWidth }}
            >
              <Trans i18nKey="onboarding_description">
                <strong style={{ fontWeight: 700 }}>
                  Agisci insieme a Levissima e AWorld.
                </strong>{" "}
                Completa le missioni, contribuisci alla challenge collettiva e{" "}
                <strong style={{ fontWeight: 700 }}>
                  scopri come anche tu puoi fare la differenza
                </strong>{" "}
                per aiutare il Pianeta!
              </Trans>
            </Text>
          </FadeFromTop>
        </Stack>
        {/* second page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 16,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 1 ? "false" : "true"}
        >
          <img
            src={partnerPoweredByAWorldLogo}
            style={{ height: 26 }}
            alt={t("partner_logo_alt")}
          />
          <Title
            fontSize={isMobile ? 26 : 28}
            textAlign="center"
            style={{ marginTop: 30 }}
            component="h1"
          >
            <Trans i18nKey="join_challenge_and_win" />
          </Title>
          <Stack
            style={{
              maxWidth: onboardingElementsMaxWidth,
              position: "relative",
              marginTop: 44,
            }}
          >
            <Text
              fontSize={isMobile ? 18 : 20}
              fontWeight={400}
              style={{ width: "100%" }}
            >
              <Trans i18nKey="onboarding_challenge_description" />
            </Text>
            <Text
              fontSize={isMobile ? 18 : 20}
              fontWeight={700}
              style={{ width: "100%", marginTop: 25 }}
            >
              {t("complete_the_missions")}
            </Text>
            <Text
              fontSize={isMobile ? 18 : 20}
              fontWeight={400}
              style={{ width: "100%" }}
            >
              {t("onboarding_leaderboard_description")}
            </Text>
            <Stack
              style={{
                width: "100%",
                height: "auto",
                minHeight: 289,
                backgroundColor: colors.backgroundWhite,
                borderRadius: 16,
                marginTop: 52,
                overflow: "hidden",
                position: "relative",
              }}
            >
              {/* terms and conditions button */}
              <ButtonBase
                disabled={loading}
                style={{ width: "100%", height: "calc(100% / 3)" }}
                onClick={() => {
                  setTermsAccepted((current) => !current)
                }}
                tabIndex={currentPage === 1 ? 0 : -1}
                aria-hidden={currentPage === 1 ? "false" : "true"}
                aria-live="assertive"
                role="checkbox"
                aria-checked={termsAccepted ? "true" : "false"}
              >
                <Stack
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: 16,
                    position: "relative",
                  }}
                >
                  <Title fontSize={isMobile ? 18 : 20} lineHeight="25px">
                    {t("terms_and_conditions")}
                  </Title>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    lineHeight={isMobile ? "18px" : "20px"}
                    fontWeight={400}
                    style={{ width: "80%" }}
                  >
                    <Trans i18nKey="terms_and_conditions_acceptance">
                      Accetto{" "}
                      <span
                        style={{
                          color: colors.primary,
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          fontSize: isMobile ? 14 : 16,
                          paddingInline: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setTermsAlertOpen(true)
                        }}
                        role="button"
                        tabIndex={currentPage === 1 ? 0 : -1}
                        aria-hidden={currentPage === 1 ? "false" : "true"}
                      >
                        i termini e le condizioni
                      </span>{" "}
                      del servizio
                    </Trans>
                  </Text>
                  <div
                    style={{
                      width: isMobile ? 24 : 26,
                      height: isMobile ? 24 : 26,
                      position: "absolute",
                      right: 16,
                      backgroundColor: termsAccepted
                        ? colors.primary
                        : colors.backgroundWhite,
                      border: "1.5px solid " + colors.primary,
                      borderRadius: "100%",
                      transition: "150ms",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={checkWhiteIcon}
                      style={{ width: isMobile ? 12 : 14 }}
                      alt=""
                    />
                  </div>
                </Stack>
              </ButtonBase>
              {/* privacy policy button */}
              <ButtonBase
                disabled={loading}
                style={{ width: "100%", height: "calc(100% / 3)" }}
                onClick={() => {
                  setPrivacyAccepted((current) => !current)
                }}
                tabIndex={currentPage === 1 ? 0 : -1}
                aria-hidden={currentPage === 1 ? "false" : "true"}
                role="checkbox"
                aria-checked={privacyAccepted ? "true" : "false"}
              >
                <Stack
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: 16,
                    position: "relative",
                  }}
                >
                  <Title fontSize={isMobile ? 18 : 20} lineHeight="25px">
                    {t("privacy_policy")}
                  </Title>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    lineHeight={isMobile ? "18px" : "20px"}
                    fontWeight={400}
                    style={{ width: "80%" }}
                  >
                    <Trans i18nKey="privacy_policy_acceptance">
                      Ho preso visione della{" "}
                      <span
                        style={{
                          color: colors.primary,
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          fontSize: isMobile ? 14 : 16,
                          paddingInline: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setPrivacyAlertOpen(true)
                        }}
                        role="button"
                        tabIndex={currentPage === 1 ? 0 : -1}
                        aria-hidden={currentPage === 1 ? "false" : "true"}
                      >
                        politica di riservatezza
                      </span>
                    </Trans>
                  </Text>
                  <div
                    style={{
                      width: isMobile ? 24 : 26,
                      height: isMobile ? 24 : 26,
                      position: "absolute",
                      right: 16,
                      backgroundColor: privacyAccepted
                        ? colors.primary
                        : colors.backgroundWhite,
                      border: "1.5px solid " + colors.primary,
                      borderRadius: "100%",
                      transition: "150ms",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={checkWhiteIcon}
                      style={{ width: isMobile ? 12 : 14 }}
                      alt=""
                    />
                  </div>
                </Stack>
              </ButtonBase>
              {/* contest button */}
              <ButtonBase
                disabled={loading}
                style={{ width: "100%", height: "calc(100% / 3 + 40px)" }}
                onClick={() => {
                  setContestAccepted((current) => !current)
                }}
                tabIndex={currentPage === 1 ? 0 : -1}
                aria-hidden={currentPage === 1 ? "false" : "true"}
                role="checkbox"
                aria-checked={contestAccepted ? "true" : "false"}
              >
                <Stack
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: 16,
                    position: "relative",
                  }}
                >
                  <Title fontSize={isMobile ? 18 : 20} lineHeight="25px">
                    {t("contest_rules")}
                  </Title>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    lineHeight={isMobile ? "18px" : "20px"}
                    fontWeight={400}
                    style={{ width: "80%" }}
                  >
                    <Trans i18nKey="contest_rules_description">
                      Dichiaro di aver letto e compreso il{" "}
                      <strong
                        role="button"
                        tabIndex={0}
                        onClick={(e) => {
                          e.stopPropagation()
                          setContestAlertOpen(true)
                        }}
                        style={{
                          color: colors.primary,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        regolamento del concorso
                      </strong>{" "}
                      e che la partecipazione allo stesso prevede la creazione
                      di un account AWorld
                    </Trans>
                  </Text>
                  <div
                    style={{
                      width: isMobile ? 24 : 26,
                      height: isMobile ? 24 : 26,
                      position: "absolute",
                      right: 16,
                      backgroundColor: contestAccepted
                        ? colors.primary
                        : colors.backgroundWhite,
                      border: "1.5px solid " + colors.primary,
                      borderRadius: "100%",
                      transition: "150ms",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={checkWhiteIcon}
                      style={{ width: isMobile ? 12 : 14 }}
                      alt=""
                    />
                  </div>
                </Stack>
              </ButtonBase>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* continue and go back buttons */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: currentPage === 0 ? 22 : 90,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
          zIndex: 1,
        }}
      >
        <Button
          loading={loading}
          title={t("continue")}
          width="100%"
          onClick={() => {
            if (canClick.current) {
              if (!currentPage) {
                const container = document.getElementById(
                  "onboarding-container"
                )
                container?.scrollTo({
                  left: window.innerWidth,
                  // behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  behavior: "auto",
                })
                setCurrentPage(1)

                if (screenReaderEnabled.current) {
                  focusElement("onboarding-container")
                }

                canClick.current = false
                setTimeout(() => {
                  canClick.current = true
                }, 500)
              } else {
                if (termsAccepted && privacyAccepted && contestAccepted) {
                  setLoading(true)
                  signUp()
                } else {
                  setMissingAlertOpen(true)
                }
              }
            }
          }}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          {t("continue")}
        </Button>
      </FadeFromBottom>
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: currentPage === 0 ? -56 : 22,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
          zIndex: 1,
        }}
      >
        <Button
          disabled={loading}
          title={t("go_back")}
          outlined
          width="100%"
          onClick={() => {
            if (canClick.current) {
              const container = document.getElementById("onboarding-container")
              container?.scrollTo({
                left: 0,
                // behavior: screenReaderEnabled.current ? "auto" : "smooth",
                behavior: "auto",
              })
              setCurrentPage(0)

              if (screenReaderEnabled.current) {
                focusElement("onboarding-container")
              }

              canClick.current = false
              setTimeout(() => {
                canClick.current = true
              }, 500)
            }
          }}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          {t("go_back")}
        </Button>
      </FadeFromBottom>
      {/* background (desktop only) */}
      {!isMobile && (
        <img
          src={onboardingBackgroundDesktopImage}
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            objectFit: "cover",
            objectPosition: "bottom",
          }}
          alt=""
        />
      )}
      {/* missing alert */}
      <Alert
        open={missingAlertOpen}
        title={t("something_is_missing")}
        description={t("consents_warning")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setMissingAlertOpen(false)
        }}
      />
      {/* full screen alerts for terms and privacy */}
      <AlertFullScreenPDF
        open={termsAlertOpen}
        setOpen={setTermsAlertOpen}
        file="https://dist.aworld.click/integrations/levissima/files/terms-and-conditions.pdf"
      />
      <AlertFullScreenPDF
        open={privacyAlertOpen}
        setOpen={setPrivacyAlertOpen}
        file="https://dist.aworld.click/integrations/levissima/files/privacy-policy-v2.pdf"
      />
      <AlertFullScreenPDF
        open={contestAlertOpen}
        setOpen={setContestAlertOpen}
        file={challengeRules}
      />
    </Stack>
  )
}

export default OnboardingMobile
