import { ButtonBase, Stack } from "@mui/material"
import Mission from "../../../models/mission"
import { defaultBoxShadowDarker } from "../../../services/config/constants"
import { scrollWindowToTop } from "../../../services/utils/utils"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { useNavigate } from "react-router-dom"
import extraActMissionImage from "../../../assets/images/extraMissions/act-extra-desktop.png"
import extraActMissionCompletedImage from "../../../assets/images/extraMissions/act-extra-desktop-completed.png"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import { useTranslation } from "react-i18next"
import PointsBadge from "../../global/common/PointsBadge"
import Text from "../../global/common/Text"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"

const ExtraMissionCardDesktop = ({ mission }: { mission: Mission }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setCurrentMission } = useContext(MainContext)

  return (
    <ButtonBase
      aria-label={t("go_to_specific_mission", { mission: mission.title })}
      disabled={mission.satisfied}
      style={{
        width: "100%",
        height: 178,
        minHeight: 178,
        borderRadius: 16,
        backgroundImage: `url(${
          mission.satisfied
            ? extraActMissionCompletedImage
            : extraActMissionImage
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: defaultBoxShadowDarker,
      }}
      onClick={() => {
        scrollWindowToTop()

        setCurrentMission(mission)
        localStorage.setItem("currentMission", JSON.stringify(mission))

        navigate(mission.navigationPath)
      }}
    >
      <Stack
        justifyContent="center"
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: 388,
          position: "relative",
        }}
      >
        {mission.satisfied ? (
          <>
            <Stack direction="row" style={{ gap: 10 }}>
              <Stack
                justifyContent="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  border: "1.5px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={18} color={colors.tertiary}>
                  {t(mission.period.toLowerCase())}
                </Title>
              </Stack>
            </Stack>
            <Title
              fontSize={20}
              color={colors.textWhite}
              style={{
                marginTop: 20,
                textTransform: "uppercase",
              }}
            >
              {t("mission_completed") + "!"}
            </Title>
            <Title fontSize={20} color={colors.textWhite}>
              {t("all_extra_points")}
            </Title>
            <div
              className="center"
              style={{
                width: 48,
                height: 48,
                borderRadius: "100%",
                backgroundColor: colors.missions.act,
                position: "absolute",
                top: 67,
                right: 69,
                border: "2.66px solid white",
              }}
            >
              <img src={checkWhiteIcon} style={{ width: 20 }} alt="" />
            </div>
          </>
        ) : (
          <>
            <Stack direction="row" style={{ gap: 10 }}>
              <Stack
                justifyContent="center"
                style={{
                  width: "auto",
                  height: 40,
                  borderRadius: 4,
                  paddingInline: 10,
                  border: "1.5px solid " + colors.tertiary,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Title fontSize={18} color={colors.tertiary}>
                  {t(mission.period.toLowerCase())}
                </Title>
              </Stack>
              <PointsBadge
                points={mission.points}
                pointPerActivity={mission.pointPerActivity}
              />
            </Stack>
            <Title
              fontSize={20}
              style={{
                marginTop: 20,
              }}
            >
              {mission.title}
            </Title>
            <Text
              fontSize={16}
              fontWeight={400}
              style={{ marginTop: 8, fontStyle: "italic" }}
            >
              {t("up_to_n_points", { count: mission.target })}
            </Text>
            <Title
              fontSize={18}
              color={colors.textWhite}
              style={{
                textTransform: "uppercase",
                position: "absolute",
                top: 84,
                right: 64,
                textDecoration: "underline",
              }}
            >
              {t("act")}
            </Title>
          </>
        )}
      </Stack>
    </ButtonBase>
  )
}

export default ExtraMissionCardDesktop
