import { ButtonBase, Skeleton, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { colors } from "../../services/config/colors"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import FadeFromTop from "../animations/FadeFromTop"
import infoIcon from "../../assets/icons/info-primary.svg"
import lockWhiteIcon from "../../assets/icons/lock-white.svg"
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import AppearFromSide from "../animations/AppearFromSide"
import months from "../../services/config/months"
import ChallengeProgressBar from "./common/ChallengeProgressBar"
import { MainContext } from "../../controllers/main"
import { UsersContext } from "../../controllers/users"
import AlertInfo from "../global/common/AlertInfo"
import SavingCard from "../actions/common/SavingCard"
import PrizeListItem from "./common/PrizeListItem"
import Prize from "../../models/prize"
import Challenge from "../../models/challenge"
import Button from "../global/common/Button"
import { numberWithSeparators } from "../../services/utils/utils"
import { challengeRules } from "../../services/config/constants"

const ChallengeMobile = ({
  displayedChallenge,
  displayedSavings,
  displayedLeaderboardLoading,
  oldPrizes,
  prizes,
}: {
  displayedChallenge: Challenge
  displayedSavings: {
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null
  displayedLeaderboardLoading: boolean
  oldPrizes: Prize[]
  prizes: Prize[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isChallengeOver, isOldChallenge, setIsOldChallenge } =
    useContext(MainContext)
  const { leaderboard, leaderboardError, getLeaderboard } =
    useContext(UsersContext)

  // what is a challenge alert
  const [challengeInfoAlertOpen, setChallengeInfoAlertOpen] =
    useState<boolean>(false)

  return (
    <div style={{ width: "100%", minHeight: "100%" }}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        {/* back button */}
        <BackButtonMobile
          style={{ position: "absolute", top: 22, left: 16, zIndex: 1 }}
          onClick={() => {
            navigate("/")
          }}
        />
        {/* top background */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 170,
            minHeight: 170,
            backgroundImage: `url(${displayedChallenge.image})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            top: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            display: "flex",
            justifyContent: "center",
          }}
        />
        {/* header */}
        <FadeFromTop
          style={{
            width: "calc(100% - 32px)",
            marginTop: 125,
          }}
        >
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              backgroundColor: colors.backgroundWhite,
              boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
              borderRadius: 10,
              position: "relative",
              padding: 24,
              gap: 16,
            }}
          >
            <Text
              fontSize={12}
              lineHeight="16px"
              fontWeight={700}
              color={colors.primary}
              component="h1"
              style={{ fontStyle: "italic" }}
            >
              {t("collective_challenge").toUpperCase()}
            </Text>
            <Title fontSize={20} lineHeight="25px" textAlign="center">
              {displayedChallenge.title}
            </Title>
            <ButtonBase
              disableRipple
              style={{
                height: 22,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => {
                setChallengeInfoAlertOpen(true)
              }}
            >
              <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
              <Text
                fontSize={12}
                lineHeight="16px"
                fontWeight={700}
                style={{ marginLeft: 5 }}
                color={colors.primary}
              >
                {t("what_is_a_challenge")}
              </Text>
            </ButtonBase>
            <Stack
              direction="row"
              style={{
                width: "100%",
                height: 38,
                position: "relative",
              }}
            >
              <Stack style={{ gap: 4 }}>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="14px"
                  color={colors.textSecondary}
                >
                  {t("starts")}
                </Text>
                <Text fontSize={14} fontWeight={700} lineHeight="20px">
                  {`${new Date(displayedChallenge.startDate).getDate()} ${t(
                    months[new Date(displayedChallenge.startDate).getMonth()]
                  ).toLowerCase()} ${new Date(
                    displayedChallenge.startDate
                  ).getFullYear()}`}
                </Text>
              </Stack>
              <Stack style={{ gap: 4, position: "absolute", right: 0 }}>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="14px"
                  color={colors.textSecondary}
                >
                  {t("ends")}
                </Text>
                <Text fontSize={14} fontWeight={700} lineHeight="20px">
                  {`${new Date(displayedChallenge.endDate).getDate()} ${t(
                    months[new Date(displayedChallenge.endDate).getMonth()]
                  ).toLowerCase()} ${new Date(
                    displayedChallenge.endDate
                  ).getFullYear()}`}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              style={{ width: "100%", position: "relative" }}
            >
              <Text
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                color={colors.textSecondary}
              >
                {numberWithSeparators(displayedChallenge.currentAmount)}{" "}
                {t("missions").toLowerCase()}
              </Text>
              <Text
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                color={colors.textSecondary}
                style={{ position: "absolute", right: 0 }}
              >
                {numberWithSeparators(displayedChallenge.targetAmount)}{" "}
                {t("missions").toLowerCase()}
              </Text>
            </Stack>
            <ChallengeProgressBar
              percentage={Math.round(
                (displayedChallenge.currentAmount /
                  displayedChallenge.targetAmount) *
                  100
              )}
              challengeTargetAmount={displayedChallenge.targetAmount}
              style={{ marginTop: -8 }}
            />
          </Stack>
        </FadeFromTop>
        {/* countdown card */}
        {isChallengeOver && (
          <AppearFromSide
            style={{ width: "100%", paddingInline: 16, marginTop: 24 }}
          >
            <Stack
              alignItems="center"
              gap={2}
              style={{
                width: "100%",
                backgroundColor: colors.primary,
                borderRadius: 10,
                paddingTop: 22,
                paddingBottom: 22,
              }}
            >
              <div
                className="center"
                style={{
                  height: 22,
                  minHeight: 22,
                  paddingInline: 10,
                  borderRadius: 22,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <Text
                  fontSize={14}
                  fontWeight={600}
                  color={colors.primary}
                  style={{ marginTop: 4 }}
                >
                  CHALLENGE TERMINATA
                </Text>
              </div>
              <Text
                fontSize={14}
                fontWeight={400}
                color={colors.textWhite}
                style={{
                  fontStyle: "italic",
                  textTransform: "uppercase",
                  marginTop: 8,
                }}
              >
                {t("new_challenge_in")}
              </Text>
              <Stack direction="row" alignItems="center" gap={1}>
                <img
                  src={lockWhiteIcon}
                  style={{ width: 30, height: 30 }}
                  alt=""
                />
                <Title
                  fontSize={24}
                  color={colors.textWhite}
                  style={{ marginTop: 7, textTransform: "uppercase" }}
                >
                  Stay tuned
                </Title>
              </Stack>
            </Stack>
          </AppearFromSide>
        )}
        {/* challenge body */}
        {/* prizes */}
        <AppearFromSide fade style={{ width: "100%", marginTop: 24 }}>
          <Stack style={{ paddingInline: 16 }}>
            <Title fontSize={18} lineHeight="25px" component="h2">
              {t("prizes")}
            </Title>
            {/* <ButtonBase
              disableRipple
              style={{
                width: "fit-content",
                justifyContent: "flex-start",
                marginTop: 16,
              }}
              aria-label={t("how_do_prizes_work")}
              onClick={() => {
                setPrizesAlertOpen(true)
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <img
                  src={infoIconPrimary}
                  style={{ width: 16, height: 16 }}
                  alt=""
                />
                <Title fontSize={14} lineHeight="16px" color={colors.primary}>
                  {t("how_do_prizes_work")}
                </Title>
              </Stack>
            </ButtonBase> */}
            <Stack
              style={{
                width: "100%",
                height: "auto",
                padding: 14,
                backgroundColor: "#D1DFD8",
                border: "1px solid #296044",
                borderRadius: 11,
                marginTop: 16,
                gap: 26,
              }}
            >
              {(isOldChallenge ? oldPrizes : prizes)
                .filter((prize) => !prize.draw)
                .map((prize, index) => (
                  <PrizeListItem
                    key={index}
                    heading={prize.heading}
                    title={prize.title}
                    image={prize.image}
                    value={prize.value}
                  />
                ))}
            </Stack>
            {(isOldChallenge ? oldPrizes : prizes).some(
              (prize) => prize.draw
            ) && (
              <Stack
                style={{
                  width: "100%",
                  height: "auto",
                  padding: 14,
                  backgroundColor: "#D1DFD8",
                  border: "1px solid #296044",
                  borderRadius: 11,
                  marginTop: 16,
                  gap: 26,
                }}
              >
                {(isOldChallenge ? oldPrizes : prizes)
                  .filter((prize) => prize.draw)
                  .map((prize, index) => (
                    <PrizeListItem
                      key={index}
                      heading={prize.heading}
                      title={prize.title}
                      image={prize.image}
                      value={prize.value}
                    />
                  ))}
              </Stack>
            )}
            {isChallengeOver && (
              <Stack
                alignItems="center"
                style={{
                  width: "100%",
                  height: "auto",
                  padding: 14,
                  backgroundColor: "#D1DFD8",
                  border: "1px solid #296044",
                  borderRadius: 11,
                  marginTop: 16,
                  gap: 16,
                }}
              >
                <Title fontSize={18} color={colors.primary} textAlign="center">
                  SCOPRI I 3 FORTUNATI VINCITORI DEL PREMIO AD ESTRAZIONE
                </Title>
                <Text fontSize={16} textAlign="center" fontWeight={400}>
                  ESTRAZIONE ENTRO IL:
                </Text>
                <div
                  className="center"
                  style={{
                    width: 220,
                    height: 45,
                    backgroundColor: colors.primary,
                    borderRadius: 26,
                  }}
                >
                  <Title
                    fontSize={18}
                    color={colors.textWhite}
                    style={{ textTransform: "uppercase", marginTop: 5 }}
                  >
                    20 dicembre
                  </Title>
                </div>
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={colors.primary}
                  style={{ marginTop: 8 }}
                >
                  <span
                    role="button"
                    style={{
                      fontWeight: 700,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      window.open(challengeRules)
                    }}
                  >
                    Leggi qui
                  </span>{" "}
                  il regolamento completo.
                </Text>
              </Stack>
            )}
          </Stack>
        </AppearFromSide>
        {/* leaderboard */}
        {isChallengeOver ? (
          <AppearFromSide
            fade
            delay={0.05}
            style={{ width: "100%", marginTop: 24 }}
          >
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: 25,
                  position: "relative",
                  paddingInline: 16,
                }}
              >
                <Title fontSize={18} lineHeight="25px" component="h2">
                  {t("winners")}*
                </Title>
              </Stack>
              {leaderboard.length ? (
                <Stack style={{ marginTop: 16, paddingInline: 16, gap: 16 }}>
                  {leaderboard
                    .filter((item) => item.position <= 5)
                    .map((item, index) => (
                      <LeaderboardCard
                        key={item.sub}
                        place={item.position}
                        name={`${item.first_name} ${item.last_name}`}
                        sub={item.sub}
                        points={item.points}
                        profileImage={item.profileImage}
                      />
                    ))}
                </Stack>
              ) : (
                <Text fontSize={16} paddingLeft={16} style={{ marginTop: 16 }}>
                  {t("leaderboard_empty_state")}.
                </Text>
              )}
              <Text
                fontSize={14}
                fontWeight={400}
                style={{ marginTop: 26, paddingInline: 16 }}
              >
                *Tra i partecipanti a pari merito, entro il 20 dicembre, si
                procederà a un'estrazione per l'assegnazione del premio come
                previsto da{" "}
                <span
                  role="button"
                  style={{
                    fontWeight: 700,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.open(challengeRules)
                  }}
                >
                  regolamento
                </span>
                .
              </Text>
            </Stack>
          </AppearFromSide>
        ) : (
          <AppearFromSide fade style={{ width: "100%", marginTop: 24 }}>
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "100%",
                  height: 25,
                  position: "relative",
                  paddingInline: 16,
                }}
              >
                <Title fontSize={18} lineHeight="25px" component="h2">
                  {isOldChallenge ? t("winners") : t("leaderboard")}
                </Title>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  color={colors.textSecondary}
                  style={{ position: "absolute", right: 16 }}
                >
                  {displayedChallenge.userCount} {t("partecipants")}
                </Text>
              </Stack>
              {displayedLeaderboardLoading ? (
                <Stack style={{ marginTop: 16, paddingInline: 16, gap: 16 }}>
                  <Skeleton
                    variant="rectangular"
                    style={{ width: "100%", height: 78, borderRadius: 10 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ width: "100%", height: 78, borderRadius: 10 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ width: "100%", height: 78, borderRadius: 10 }}
                  />
                </Stack>
              ) : leaderboardError ? (
                <Stack gap={3} style={{ marginTop: 16, paddingInline: 16 }}>
                  <Text fontSize={16}>{t("leaderboard_error")}.</Text>
                  <Button
                    title={t("retry")}
                    width={170}
                    style={{ height: 36, alignSelf: "center" }}
                    onClick={() => {
                      getLeaderboard(false, false)
                    }}
                  >
                    {t("retry")}
                  </Button>
                </Stack>
              ) : leaderboard.length ? (
                <Stack style={{ marginTop: 16, paddingInline: 16, gap: 16 }}>
                  {leaderboard.slice(0, 3).map((item, index) => (
                    <LeaderboardCard
                      key={item.sub}
                      place={item.position}
                      name={`${item.first_name} ${item.last_name}`}
                      sub={item.sub}
                      points={item.points}
                      profileImage={item.profileImage}
                    />
                  ))}
                  {leaderboard.length > 3 && !displayedLeaderboardLoading ? (
                    <ButtonBase
                      style={{ width: "fit-content" }}
                      aria-label={t("see_all")}
                      onClick={() => {
                        navigate("/performance", {
                          state: { viewLeaderboard: true },
                        })
                      }}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={700}
                        color={colors.primary}
                        style={{ textDecoration: "underline" }}
                      >
                        {t("see_all")}
                      </Text>
                    </ButtonBase>
                  ) : null}
                </Stack>
              ) : (
                <Text fontSize={16} paddingLeft={16} style={{ marginTop: 16 }}>
                  {t("leaderboard_empty_state")}.
                </Text>
              )}
            </Stack>
          </AppearFromSide>
        )}
        {/* description */}
        <AppearFromSide
          fade
          delay={0.05}
          style={{ width: "100%", marginTop: 24 }}
        >
          <Stack style={{ paddingInline: 16 }}>
            <Title
              fontSize={18}
              lineHeight="25px"
              style={{ marginBottom: 16 }}
              component="h2"
            >
              {t("description")}
            </Title>
            <div
              className="html"
              dangerouslySetInnerHTML={{
                __html: displayedChallenge.description.replace(
                  "https://drive.google.com/file/d/1q-1ytdiEuZMg79TePO9WGbX2lrIByQMq/view",
                  "https://dist.aworld.click/integrations/levissima/files/contest-rules-v2.pdf"
                ),
              }}
              style={{
                fontSize: 16,
                fontWeight: 300,
                lineHeight: "22px",
                color: colors.text,
                overflow: "hidden",
              }}
            />
          </Stack>
        </AppearFromSide>
        {/* savings */}
        {displayedSavings &&
        (displayedSavings.co2Saving ||
          displayedSavings.waterSaving ||
          displayedSavings.energySaving) ? (
          <AppearFromSide
            fade
            delay={0.1}
            style={{ width: "100%", marginTop: 24 }}
          >
            <Stack>
              <Title
                fontSize={18}
                lineHeight="25px"
                paddingLeft={16}
                component="h2"
              >
                {t("community_savings")}
              </Title>
              <Stack
                direction="row"
                gap={1.5}
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  paddingInline: 16,
                  marginTop: 16,
                }}
              >
                {displayedSavings.co2Saving ? (
                  <SavingCard
                    metric="co2"
                    amount={displayedSavings.co2Saving}
                  />
                ) : null}
                {displayedSavings.waterSaving ? (
                  <SavingCard
                    metric="water"
                    amount={displayedSavings.waterSaving}
                  />
                ) : null}
                {displayedSavings.energySaving ? (
                  <SavingCard
                    metric="energy"
                    amount={displayedSavings.energySaving}
                  />
                ) : null}
              </Stack>
            </Stack>
          </AppearFromSide>
        ) : null}
        <Button
          title={
            isOldChallenge
              ? t("view_active_challenge")
              : t("view_past_challenge")
          }
          disabled={displayedLeaderboardLoading}
          onClick={() => {
            setIsOldChallenge((current) => !current)
          }}
          style={{ marginTop: 20, height: 46 }}
        >
          {isOldChallenge
            ? t("view_active_challenge")
            : t("view_past_challenge")}
        </Button>
      </div>
      {/* challenge info alert */}
      <AlertInfo
        open={challengeInfoAlertOpen}
        setOpen={setChallengeInfoAlertOpen}
        title={t("what_is_a_challenge")}
        description={t("challenges_description")}
        descriptionAlign="center"
      />
    </div>
  )
}

const LeaderboardCard = ({
  place,
  name,
  sub,
  points,
  profileImage,
}: {
  place: number
  name: string
  sub: string
  points: number
  profileImage: string
}) => {
  const { t } = useTranslation()
  const { user } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: "100%",
        height: 78,
        borderRadius: 10,
        padding: 12,
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor:
          user!.sub === sub ? colors.background : colors.backgroundWhite,
      }}
    >
      <div
        style={{
          width: "auto",
          height: 22,
          paddingInline: 6,
          borderRadius: 4,
          backgroundColor: colors.primary,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Title fontSize={12} color={colors.textWhite}>
          {place}°
        </Title>
      </div>
      <div
        style={{
          width: 32,
          minWidth: 32,
          height: 32,
          borderRadius: "100%",
          marginLeft: 16,
          backgroundImage: `url(${profileImage}), url(https://cdn.aworld.io/users/default/profile.jpg)`,
          backgroundSize: "105%, 100%",
          backgroundPosition: "center",
        }}
      />
      <Stack
        style={{
          width: "100%",
          marginLeft: 12,
          gap: 4,
        }}
      >
        <Title
          fontSize={14}
          lineHeight="20px"
          color={user!.sub === sub ? colors.primary : colors.text}
          style={{
            width: "calc(100% - 100px)",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            wordWrap: "break-word",
          }}
        >
          {user?.sub === sub ? t("you") : name}
        </Title>
        <Text
          fontSize={12}
          fontWeight={500}
          lineHeight="14px"
          color={user!.sub === sub ? colors.primary : colors.textSecondary}
        >
          {points} {t("points_extended").toLowerCase()}
        </Text>
      </Stack>
    </Stack>
  )
}

export default ChallengeMobile
